/* ==========================================================================
   XOR skin - Based off of Base16 Atelier Plateau
   ========================================================================== */

/* Colors */
$background-color: #1b1818 !default;
$text-color: #8a8585 !default;

$primary-color: #ca4949 !default;
$success-color: #4b8b8b !default;
$warning-color: #b45a3c !default;
$danger-color: #ca4949 !default;
$info-color: #5485b6 !default;

$border-color: mix(#fff, $background-color, 20%) !default;
$code-background-color: mix(#000, $background-color, 15%) !default;
$code-background-color-dark: mix(#000, $background-color, 20%) !default;
$form-background-color: mix(#000, $background-color, 15%) !default;
$footer-background-color: mix($primary-color, #000, 10%) !default;
$link-color: $primary-color !default;
$link-color-hover: mix(#fff, $link-color, 25%) !default;
$link-color-visited: mix(#000, $link-color, 25%) !default;
$masthead-link-color: $text-color !default;
$masthead-link-color-hover: mix(#000, $text-color, 20%) !default;
$navicon-link-color-hover: mix(#000, $background-color, 30%) !default;

/* neon syntax highlighting (base16) */
$base00: #1b1818 !default;
$base01: #292424 !default;
$base02: #585050 !default;
$base03: #655d5d !default;
$base04: #7e7777 !default;
$base05: #8a8585 !default;
$base06: #e7dfdf !default;
$base07: #f4ecec !default;
$base08: #ca4949 !default;
$base09: #b45a3c !default;
$base0a: #a06e3b !default;
$base0b: #4b8b8b !default;
$base0c: #5485b6 !default;
$base0d: #7272ca !default;
$base0e: #8464c4 !default;
$base0f: #bd5187 !default;

.author__urls.social-icons .svg-inline--fa,
.page__footer-follow .social-icons .svg-inline--fa {
  color: inherit;
}

/* next/previous buttons */
.pagination--pager {
  color: $text-color;
  background-color: $primary-color;
  border-color: transparent;

  &:visited {
    color: $text-color;
  }
}
